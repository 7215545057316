<template>
  <div id="event_info" class="event_public">
    <EventHeader :eventHeaderItems="eventHeaderItems" />

    <!-- <PageSubHeader
      :pageSubHeader="pageSubHeader"
      :urlQuery="urlQuery"
      :hostType="hostType"
      class="annual"
    /> -->

    <div class="information">
      <div class="title w1100">
        <h3 class="txt-bold">活動資訊</h3>
        <img src="@/statics/img/index/icon_title2.svg" alt="">
      </div>
      <div class="w1000">
        <!-- 活動資料 -->
        <div class="column_list">
          <div class="main_title h4 txt-light_green txt-bold">活動資料</div>
          <div class="wrap data_location">
            <div class="col50" v-if="eventStartDate && eventEndDate">
              <div class="content_group">
                <div class="title txt-bold">活動時間</div>
                <div class="content txt-dark_green">
                  {{ eventStartDate }} ~ {{ eventEndDate }}
                </div>
              </div>
            </div>
            <div class="col50" v-if="applyStartDate && applyEndDate">
              <div class="content_group">
                <div class="title txt-bold">報名期間</div>
                <div class="content txt-dark_green">
                  {{ applyStartDate }} ~ {{ applyEndDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div class="col50" v-if="eventInfo.place">
              <div class="content_group">
                <div class="title txt-bold">活動地點</div>
                <div class="content txt-dark_green">{{ eventInfo.place }}</div>
              </div>
            </div>
            <div
              class="col50"
              v-if="typeof eventInfo.apply_count === 'number'
              && typeof eventInfo.apply_limit === 'number'
              && parseInt(eventInfo.host_type, 10) !== 2"
            >
              <div class="content_group">
                <div class="title txt-bold">報名人數</div>
                <div class="content txt-dark_green">
                  <span class="txt-red">{{ eventInfo.apply_count }}</span>
                  /{{ eventInfo.apply_limit }}
                </div>
              </div>
            </div>
          </div>
          <div class="wrap" v-if="foodTypeRender.length > 0">
            <div class="col100">
              <div class="content_group">
                <div class="title txt-bold">餐點</div>
                <div class="content txt-dark_green">
                  <template v-for="(type, index) in foodTypeRender">
                    <span :key="index" v-if="index !== 0">、</span>
                    <span :key="type.uuid">{{ type.name }}</span>
                  </template>
                  <template v-if="foodMemo">&nbsp;({{ foodMemo }})</template>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap" v-if="eventInfo.content">
            <div class="col100">
              <div class="content_group">
                <div class="title txt-bold">活動目的</div>
                <div class="content txt-dark_green" v-html="eventInfo.content"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="column_list" v-if="Object.keys(classInfo).length > 0">
          <div class="main_title h4 txt-light_green txt-bold">課程表</div>
          <div class="wrap">
            <div class="col100">
              <div class="content_group">
                <img :src="`${API_URL}${classInfo.path}`" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="column_list" v-if="isPriceExist">
          <div class="main_title h4 txt-light_green txt-bold mg-b-30">報名資訊</div>
          <div class="form_box">
            <form>
              <div class="form_description">
              </div>
              <div class="form_interactive">
                <template v-for="(value, key) in eventInfo.price">
                  <div
                    class="fixed_info w50"
                    :key="key"
                    v-if="value && value >= 0"
                  >
                    <div class="content_group">
                      <div class="title txt-bold">{{ levelConverter(key) }}</div>
                      <div class="content txt-dark_green">{{ value }}元</div>
                    </div>
                  </div>
                </template>
              </div>
            </form>
          </div>
        </div>

        <div class="column_list" v-if="isFractionsExist">
          <div class="main_title h4 txt-light_green txt-bold mg-b-30">繼續教育認證</div>
          <div class="form_box">
            <form>
              <!-- <div class="form_interactive">
                <template v-for="item in fractionAB">
                  <div class="fixed_info w50" :key="item.name">
                    <div class="content_group">
                      <div class="title txt-bold">本醫學會{{ item.name }}類積分</div>
                      <div class="content txt-dark_green">{{ item.num }}</div>
                    </div>
                  </div>
                </template>
              </div> -->
              <div class="form_interactive">
                <template v-for="(value, key) in eventInfo.fractions">
                  <div
                    class="fixed_info w50"
                    :key="`fraction_${key}`"
                    v-if="(typeof value === 'string' && value !== '') || typeof value === 'number'"
                  >
                    <div class="content_group">
                      <div class="title txt-bold">{{ fractionName(key) }}積分</div>
                      <div class="content txt-dark_green">{{ value }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </form>
          </div>
        </div>

        <div class="column_list" v-if="Object.keys(trafficInfo).length > 0">
          <div class="main_title h4 txt-light_green txt-bold">交通資訊</div>
          <div class="content_group">
            <img class="annual_info_sub_map" :src="`${API_URL}${trafficInfo.path}`" alt="">
          </div>
        </div>

        <!-- 附件下載 -->
        <div class="column_list" v-if="attachment.length > 0 && parseInt(hostType, 10) === 1">
          <div class="main_title h4 txt-light_green txt-bold">附件下載</div>
          <div class="content_group">
            <template v-for="item in attachment">
              <a
                :key="`attach_${item}`"
                :href="`${API_URL}${eventInfo.files[item].path}`"
                target="_blank"
                download=""
                class="file_link txt-dark_green"
              >{{ eventInfo.files[item].title }}</a>
              <br :key="`br_${item}`">
            </template>
          </div>
        </div>

        <div class="column_list" v-if="eventRefund">
          <div class="main_title h4 txt-light_green txt-bold">退費辦法</div>
          <div class="wrap">
            <div class="col100">
              <div class="content_group">
                <div class="content txt-dark_green">
                  {{ eventRefund }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column_list" v-if="isContactExist">
          <div class="main_title h4 txt-light_green txt-bold">主辦/協辦單位與聯絡資訊</div>
          <div class="wrap">
            <div class="col50" v-if="eventInfo.host">
              <div class="content_group">
                <div class="title txt-bold">主辦單位</div>
                <div class="content txt-dark_green">{{ eventInfo.host }}</div>
              </div>
            </div>
            <div class="col50" v-if="eventInfo.support">
              <div class="content_group">
                <div class="title txt-bold">協辦單位</div>
                <div class="content txt-dark_green">{{ eventInfo.support }}</div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div class="col50" v-if="eventInfo.contact">
              <div class="content_group">
                <div class="title txt-bold">聯絡人</div>
                <div class="content txt-dark_green">{{ eventInfo.contact }}</div>
              </div>
            </div>
            <div class="col50" v-if="eventInfo.contact_tel">
              <div class="content_group">
                <div class="title txt-bold">電話</div>
                <div class="content txt-dark_green">{{ eventInfo.contact_tel }}</div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div class="col50" v-if="eventInfo.contact_mail">
              <div class="content_group">
                <div class="title txt-bold">E-MAIL</div>
                <div class="content txt-dark_green">{{ eventInfo.contact_mail }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="column_list" v-if="eventRemark">
          <div class="main_title h4 txt-light_green txt-bold">備註</div>
          <div class="wrap">
            <div class="col100">
              <div class="content_group">
                <div class="content txt-dark_green">
                  <p v-html="eventRemark"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="btn_box txt-center" v-if="parseInt(hostType, 10) === 1">
        <router-link
          :to="`/register/event/member?event_id=${eventID}`"
          class="btn btn-apply"
        >會員報名</router-link>
        <router-link
          :to="`/register/event/nonMember?event_id=${eventID}`"
          class="btn btn-apply"
        >非會員報名</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EventHeader from '@/components/EventHeader.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterEventSubHeader } from '@/lib/const';
import { eventsView } from '@/lib/http';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { API_URL } from '@/lib/config';

export default {
  name: 'RegisterEventInfo',
  data() {
    return {
      ...RegisterEventSubHeader,
      API_URL,
      eventID: '',
      eventInfo: {},
    };
  },
  created() {
    if (this.$route.query && this.$route.query.event_id) {
      this.eventID = this.$route.query.event_id;
    } else {
      this.openModal({
        message: '尚未選擇學術活動',
        url: '/register/event',
      });
    }

    if (this.eventID) {
      eventsView({ uuid: this.eventID }).then((result) => {
        this.eventInfo = result.result.data;
      });
    }
  },
  computed: {
    ...mapGetters([
      'foodType',
      'hoursType',
    ]),
    eventHeaderItems() {
      return {
        name: this.eventInfo.name,
        eventNo: this.eventInfo.no_number,
        fraction_a: this.eventInfo.fraction_a,
        fraction_b: this.eventInfo.fraction_b,
      };
    },
    urlQuery() {
      return {
        name: 'event_id',
        value: this.eventID,
      };
    },
    hostType() {
      return _.get(this.eventInfo, 'host_type', '');
    },
    eventStartDate() {
      const date = _.get(this.eventInfo, 'start_date', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD kk:mm');
      }
      return date;
    },
    eventEndDate() {
      const date = _.get(this.eventInfo, 'end_date', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD kk:mm');
      }
      return date;
    },
    applyStartDate() {
      const date = _.get(this.eventInfo, 'apply_start_date', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD kk:mm');
      }
      return date;
    },
    applyEndDate() {
      const date = _.get(this.eventInfo, 'apply_end_date', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD kk:mm');
      }
      return date;
    },
    foodTypeRender() {
      return _.get(this.eventInfo, 'meals', []).filter((item) => item.status === true);
      // let foodText = '';
      // // if (this.foodType) {
      // //   this.foodType.forEach((item) => {
      // //     if (item.id === this.eventInfo.food_type) {
      // //       foodText = item.name;
      // //     }
      // //   });
      // // }
      // const foodTypeNum = parseInt(this.eventInfo.food_type, 10);
      // switch (foodTypeNum) {
      //   case 1:
      //     foodText = '區分葷素';
      //     break;
      //   case 2:
      //     foodText = '不分葷素';
      //     break;
      //   case 3:
      //     foodText = '不供餐';
      //     break;
      //   default:
      //     break;
      // }
      // return foodText;
    },
    foodMemo() {
      return _.get(this.eventInfo, 'memo.food', '');
    },
    fractionAB() {
      let arr = [];
      if (this.eventInfo.fraction_a) {
        arr = [...arr, { name: 'A', num: this.eventInfo.fraction_a }];
      }
      if (this.eventInfo.fraction_b) {
        arr = [...arr, { name: 'B', num: this.eventInfo.fraction_b }];
      }
      return arr;
    },
    classInfo() {
      return _.get(this.eventInfo.files, 'class', {});
    },
    trafficInfo() {
      return _.get(this.eventInfo.files, 'traffic', {});
    },
    attachment() {
      const keyArr = [];
      if (this.eventInfo.files) {
        Object.keys(this.eventInfo.files).forEach((item) => {
          if (item.indexOf('file') !== -1) {
            keyArr.push(item);
          }
        });
      }
      return keyArr;
    },
    eventRemark() {
      return _.get(this.eventInfo, 'memo.remark', '');
    },
    eventRefund() {
      return _.get(this.eventInfo, 'memo.refund', '');
    },
    isPriceExist() {
      const target = _.get(this.eventInfo, 'price', '');
      if (target) {
        const keyArr = Object.keys(target);
        for (let i = 0; i < keyArr.length; i += 1) {
          if (parseInt(target[keyArr[i]], 10) !== 'NaN' && parseInt(target[keyArr[i]], 10) >= 0) {
            return true;
          }
        }
      }
      return false;
    },
    isFractionsExist() {
      const target = _.get(this.eventInfo, 'fractions', '');
      if (target) {
        const keyArr = Object.keys(target);
        for (let i = 0; i < keyArr.length; i += 1) {
          const item = target[keyArr[i]];
          // if (parseInt(target[keyArr[i]], 10) !== 'NaN' && parseInt(target[keyArr[i]], 10) > 0) {
          //   return true;
          // }
          if ((typeof item === 'string' && item !== '') || typeof item === 'number') {
            return true;
          }
        }
      }
      return false;
    },
    isContactExist() {
      if (!this.eventInfo.host
        && !this.eventInfo.support
        && !this.eventInfo.contact
        && !this.eventInfo.contact_tel
        && !this.eventInfo.contact_tel) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    levelConverter(key) {
      let levelName = '';
      switch (parseInt(key, 10)) {
        case 1:
          levelName = '專科會員';
          break;
        case 2:
          levelName = '會員醫師';
          break;
        case 3:
          levelName = '非會員醫師';
          break;
        case 4:
          levelName = '護理人員';
          break;
        default:
          levelName = '';
      }
      return levelName;
    },
    fractionName(key) {
      let text = '';
      this.hoursType.forEach((item) => {
        if (parseInt(item.id, 10) === parseInt(key, 10)) {
          text = item.name;
        }
      });
      return text;
    },
  },
  components: {
    EventHeader,
    // eslint-disable-next-line vue/no-unused-components
    PageSubHeader,
  },
};
</script>
